var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Anchor, Button, Dialog, DialogContent, DialogFooter, DialogHeader, FlexGrid, Styler, } from '@honeycomb/ui-core';
export function FieldDialog(_a) {
    var { children, open, onClose, title, headerContent, footerContent, ctaProps, resetProps, headingProps } = _a, other = __rest(_a, ["children", "open", "onClose", "title", "headerContent", "footerContent", "ctaProps", "resetProps", "headingProps"]);
    const renderFooter = Boolean(ctaProps !== null && ctaProps !== void 0 ? ctaProps : resetProps);
    return (React.createElement(Dialog, Object.assign({ open: open, onClose: onClose, size: "l", fullScreen: "mobile-only" }, other),
        React.createElement(DialogHeader, { withCloseButton: true, title: title }, headerContent && React.createElement(Styler, { mt: 2 }, headerContent)),
        React.createElement(DialogContent, { bg: "background.offset" },
            React.createElement(Styler, { py: 4 }, children)),
        renderFooter && (React.createElement(DialogFooter, null,
            footerContent,
            React.createElement(FlexGrid, { container: true, flexDirection: "row", spacing: 5, alignItems: "center" },
                resetProps && (React.createElement(FlexGrid, { xs: "hold" },
                    React.createElement(Anchor, { size: "s", weight: "semiBold", component: "button", color: "text.link", onClick: resetProps.onClick, disabled: resetProps.disabled }, resetProps.label))),
                ctaProps && (React.createElement(FlexGrid, { xs: "fill" },
                    React.createElement(Button, { onClick: ctaProps.onClick, color: "callToAction", size: "l", fullWidth: true, disabled: ctaProps.disabled, id: `${title}_done_button` }, ctaProps.label))))))));
}
